import React, { useEffect, useState } from 'react';
import './Row.css';
import instance from './axios';

function Row({title, fetchUrl, isLargeRow = false}) {
    const [movies, setMovies] = useState([]);

    const base_url = "https://image.tmdb.org/t/p/original/";

    useEffect(() => {
        async function fetchData() {
            const request = await instance.get(fetchUrl);
            setMovies(request.data.results);
            return request;
        }

        fetchData();
    }, [fetchUrl]);
console.log(movies)

  return (
    <div className='row'>
        <h2 className='row_title'>{title}</h2>

        <div className='row_posters'>
            {movies.map(
                (movie) => 
                ((isLargeRow && movie.poster_path) ||
                (!isLargeRow && movie.backdrop_path)) && (
                    <img 
                    className={`row_poster ${isLargeRow && "row_posterLarge"}`}
                    key={movie.id}
                    src={`${base_url}${
                        isLargeRow ? movie.poster_path : movie.backdrop_path
                        
                    }`} 
                    alt={movie.name} />
                
            
            ))}
        </div>
     
    </div>

  );
}

export default Row;